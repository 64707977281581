/* eslint-disable no-useless-escape */
import {
  reset,
  magenta,
  // grey,
  // whiteBackground,
  // bold,
  // underline,
  // greyed,
  italic,
  url,
  // strikethrough,
  // boldAndUnderline
} from '../../../util/ansi';

const command = '\x1B[33m';
// const comingSoon = '\x1B[31m';
const comingSoonCMD = '\x1B[31m';
// const argument = '\x1B[32m';

const wasmerAscii = `
${magenta}                          
${magenta} ███████╗██╗███╗░░░███╗   
${magenta} ╚════██║██║████╗░████║   
${magenta} ░░███╔═╝██║██╔████╔██║   
${magenta} ██╔══╝░░██║██║╚██╔╝██║   
${magenta} ███████╗██║██║░╚═╝░██║   
${magenta} ╚══════╝╚═╝╚═╝░░░░░╚═╝   
${magenta}                          
${magenta} ██████╗░░█████╗░████████╗
${magenta} ██╔══██╗██╔══██╗╚══██╔══╝
${magenta} ██║░░██║██║░░██║░░░██║░░░
${magenta} ██║░░██║██║░░██║░░░██║░░░
${magenta} ██████╔╝╚█████╔╝░░░██║░░░
${magenta} ╚═════╝░░╚════╝░░░░╚═╝░░░
${magenta}                          
${magenta} ██████╗░███████╗██╗░░░██╗
${magenta} ██╔══██╗██╔════╝██║░░░██║
${magenta} ██║░░██║█████╗░░╚██╗░██╔╝
${magenta} ██║░░██║██╔══╝░░░╚████╔╝░
${magenta} ██████╔╝███████╗░░╚██╔╝░░
${magenta} ╚═════╝░╚══════╝░░░╚═╝░░░
${magenta}                          
${reset}`;

let welcomeMessage = `
\x1B[255;1;7m  Miles Zimmerman  ${reset}

  I like to build neat things.


About:

  > \`${command}whoami${reset}\` or \`${command}about${reset}\`.
  > ${italic}(coming soon)${reset} \`${comingSoonCMD}projects${reset}\`, \`${comingSoonCMD}resume${reset}\`.


Run any WASM module from ${url}https://wapm.io${reset}:

  > Try a module: \`${command}cowsay${reset} \x1B[33;3mhello${reset}\`.
  > Manage Wasm modules: \`${command}wapm${reset}\`.


Commands:

  > list all commands: \`${command}wapm list${reset}\`.
  > learn more: \`${command}help${reset}\`.
`;

// Only show the Wasmer logo if the browser is big enough
if (window.innerWidth > 800) {
  const welcomeMessageSplitted = welcomeMessage.split('\n');
  let splittedLogo = wasmerAscii.split('\n');
  if (welcomeMessageSplitted.length > splittedLogo.length) {
    splittedLogo = splittedLogo.concat(new Array(welcomeMessageSplitted.length - splittedLogo.length));
  }
  welcomeMessage = splittedLogo
    .map(function (e, i) {
      return ` ${e || ''}    ${reset}${welcomeMessageSplitted[i] || ''}`;
    })
    .join('\n');
}

// if (window.SharedArrayBuffer === undefined) {
//   welcomeMessage += `

// BROWSER COMPATIBILITY:
//     Your current browser does not support SharedArrayBuffer.
//     Your browser should still support more simple WASI commands, but:
//         • Infinitely looping commands ${bold}can freeze your browser${reset}
//         • Commands that require input will have a worse UX (via prompt)
//     Use the command "help" for more information.
// `;
// }

export const getWelcomeMessage = () => welcomeMessage.replace(/\n\n/g, '\n \n');
const welcome = async () => getWelcomeMessage();
export default welcome;
