export const reset = '\x1B[0m';
export const magenta = `${reset}\x1B[34;1m`;
export const greyed = `${reset}\x1B[90m`;
// medium gray
export const grey = `${reset}\x1B[37;2m`;
export const bold = `${reset}\x1B[1m`;
export const underline = `${reset}\x1B[4m`;
export const italic = `${reset}\x1B[3m`;
export const strikethrough = `${reset}\x1B[97m`;
export const boldAndUnderline = `${reset}\x1B[1m\x1B[4m`;
export const url = `${reset}\x1B[95;4m`;

export const whiteBackground = `${reset}\x1B[7;1m`;
