// import packageJson from '../../../../package.json';
import {
  reset,
  // magenta,
  // bold,
  // underline,
  url,
  // boldAndUnderline
} from '../../../util/ansi';

const message = `
\x1B[255;1;7mMiles Zimmerman${reset}
Director of Engineering @ House Ninja, Inc.

  → Github: ${url}https://github.com/mileszim${reset}
  → LinkedIn: ${url}https://www.linkedin.com/in/mileszim/${reset}
  → Twitter: ${url}https://twitter.com/mileszim${reset}
  → Email: \x1B[96mmiles@zim.dev${reset}

  → GUI Resume: ${url}https://represent.io/miles${reset}

`;

export const getMessage = () => message.replace(/\n\n/g, '\n \n');
const whoami = async () => getMessage();
export default whoami;
